import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import Main from "../components/Main";

import { PageHeader } from "../components/Typo";
import Section from "../components/Section";
// import AudioList from "../components/AudioList";

import nooitAlleen from "../images/nooitalleen.jpg";
import zingNogEens from "../images/ZingNogEens.jpg";
import bv from "../images/bv.webp";
import { Cart } from "../svg";

import Input from "../components/Input";

const AlbumInfo = styled.div`
  flex: 1;
  margin-left: 48px;

  ${media.lessThan("medium")`
    margin-left: 0px;
  `}
`;

const BuyTags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BuyTag = styled.a`
  background-color: #383838;
  text-decoration: none;
  font-weight: 300;
  color: white;
  padding: 6px 16px;
  font-size: 18px;
  text-transform: uppercase;
  margin-right: 12px;
  margin-bottom: 12px;
  border-radius: 2px;
  cursor: pointer;
`;

const Form = styled.form`
  margin-top: 48px;
`;

const Submit = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 48px;
  border: 0;
  outline: 0;
  padding: 12px 24px;
  color: white;
  font-weight: 400;
  text-transform: uppercase;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;

  & > svg {
    margin-left: 8px;
    fill: white;
  }

  &:hover {
    background-color: #3c987b;
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #d23e3e;
`;

const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const initialValues = {
  name: "",
  street: "",
  postalCode: "",
  email: "",
};

const errorMessages = {
  required: "Dit veld is verplicht",
  invalidEmail: "Dit is geen geldig e-mailadres",
};

function OrderForm() {
  const [state, setState] = React.useState({
    send: false,
    errors: {},
    values: initialValues,
  });

  function handleSend(evt) {
    evt.preventDefault();

    const errors = {};

    const { name, street, postalCode, email } = state.values;

    if (!name) {
      errors.name = "required";
    }
    if (!street) {
      errors.street = "required";
    }
    if (!email) {
      errors.email = "required";
    } else if (!emailPattern.test(email)) {
      errors.email = "invalidEmail";
    }
    if (!postalCode) {
      errors.postalCode = "required";
    }

    const hasErrors = Object.keys(errors).some((key) => Boolean(errors[key]));

    if (hasErrors) {
      setState({
        ...state,
        errors,
        send: false,
      });
      return;
    }

    fetch(`/api/order`, {
      method: "POST",
      body: JSON.stringify(state.values),
      headers: {
        "Content-Type": "application/json",
      },
    });

    setState({ ...state, values: initialValues, errors: {}, send: true });
  }

  function handleChange(field) {
    return function(evt) {
      setState({
        ...state,
        values: {
          ...state.values,
          [field]: evt.target.value,
        },
        errors: {
          ...state.errors,
          [field]: null,
        },
      });
    };
  }

  if (state.send) {
    return (
      <>
        <h2 style={{ textAlign: "center", marginTop: 50 }}>Verzonden! 👍🏼</h2>

        <p style={{ textAlign: "center" }}>
          Bedankt voor uw bestelling. U ontvangt zo spoedig mogelijk meer
          informatie.
        </p>
      </>
    );
  }

  return (
    <Form onSubmit={handleSend}>
      <Input
        style={{ marginTop: 0 }}
        value={state.values.name}
        onChange={handleChange("name")}
        placeholder="Voor- en achternaam"
      />
      {state.errors.name && (
        <ErrorMessage>{errorMessages[state.errors.name]}</ErrorMessage>
      )}
      <Input
        value={state.values.street}
        onChange={handleChange("street")}
        placeholder="Straat en huisnummer"
      />
      {state.errors.street && (
        <ErrorMessage>{errorMessages[state.errors.street]}</ErrorMessage>
      )}
      <Input
        value={state.values.postalCode}
        onChange={handleChange("postalCode")}
        placeholder="Postcode en woonplaats"
      />
      {state.errors.postalCode && (
        <ErrorMessage>{errorMessages[state.errors.postalCode]}</ErrorMessage>
      )}
      <Input
        value={state.values.email}
        onChange={handleChange("email")}
        placeholder="Email-adres"
      />
      {state.errors.email && (
        <ErrorMessage>{errorMessages[state.errors.email]}</ErrorMessage>
      )}

      <Submit>
        Bestel <Cart width={18} />
      </Submit>
    </Form>
  );
}

export default () => {
  return (
    <Main title="Luister" path="/luister">
      <PageHeader center title="Luister" icon="audio" />
      <Section
        noMargin
        flex
        style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}
      >
        <img
          alt="Buik vol"
          src={bv}
          style={{ flex: "1", objectFit: "contain", width: "50%" }}
        />
        <AlbumInfo>
          <div
            style={{
              fontWeight: 700,
              fontSize: 24,
              color: "#383838",
              marginBottom: 16,
            }}
          >
            Buik vol
          </div>
          {/* <p>
            Dat Emma Lou zelf artiest zou worden zat er al jong in. Ze groeide
            op tussen de gitaren en violen. Dat laatste instrument speelt nog
            steeds een belangrijke rol in haar repertoire.
          </p> */}

          <BuyTags>
            <BuyTag
              rel="noopener noreferrer"
              target="_blank"
              href="https://open.spotify.com/track/4WzBdFNllXBIyoz75eQWZ4"
            >
              Spotify
            </BuyTag>
            <BuyTag
              rel="noopener noreferrer"
              target="_blank"
              href="https://music.apple.com/nl/album/buik-vol-single/1770934470"
            >
              Apple Music
            </BuyTag>
            <BuyTag
              onClick={() => {
                document
                  .getElementById("form")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              BESTEL CD (€10)
            </BuyTag>
            <BuyTag
              rel="noopener noreferrer"
              target="_blank"
              href="https://deezer.page.link/G4Rz4p4D9p3vPt9s8"
            >
              Deezer
            </BuyTag>
          </BuyTags>
        </AlbumInfo>
      </Section>

      <div
        style={{
          maxWidth: 600,
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: 76,
          padding: "0px 16px",
        }}
      >
        <iframe
          title="spotify-bottom"
          src="https://open.spotify.com/embed/playlist/07Hp4WTtnRzzKGUAUcLk7i"
          width="100%"
          height="400"
          frameBorder="0"
          allow="encrypted-media"
        ></iframe>
      </div>

      {/* <AudioList
        list={[
          {
            title: "Liedje 1",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-01-Stop-de-tijd30sec.mp3"
          },
          {
            title: "Liedje 2",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-02-Spijkertje30sec.mp3"
          },
          {
            title: "Liedje 3",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-03-Meemaken-of-niet30sec.mp3"
          },
          {
            title: "Liedje 4",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-04-Hogwarts30sec.mp3"
          },
          {
            title: "Liedje 5",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-01-Stop-de-tijd30sec.mp3"
          },
          {
            title: "Liedje 6",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-01-Stop-de-tijd30sec.mp3"
          },
          {
            title: "Liedje 7",
            length: "3:33",
            url:
              "https://www.yentlendeboer.nl/wp-content/uploads/2018/10/Preview-01-Stop-de-tijd30sec.mp3"
          }
        ]}
      /> */}

      <PageHeader
        center
        title="Bestel"
        icon="shop"
        style={{ marginBottom: 16, marginTop: 96 }}
      />
      <Section
        noMargin
        flex
        style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}
      >
        <img
          alt="Nooit Alleen"
          src={nooitAlleen}
          style={{ objectFit: "contain", width: "30%" }}
        />
        <AlbumInfo>
          <p style={{}}>
            Bestel de cd ‘Nooit alleen’ via dit formulier. Nadat wij uw
            bestelling hebben binnen gekregen ontvangt u informatie over de
            betaling. Na ontvangst van de betaling wordt vervolgens de cd
            verzonden.
            <br /> <br />
            Prijs cd exclusief verzendkosten €10,-
          </p>
        </AlbumInfo>
      </Section>

      <Section
        id="form"
        style={{ maxWidth: 600, margin: "0 auto", marginTop: 32 }}
      >
        <OrderForm />
      </Section>
    </Main>
  );
};
